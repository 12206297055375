.st0{fill:#4267B2}
.st1{fill:url(#SVGID_1_)}
.st2{fill:#FFFFFF;}
.st3{fill:#C2191E;}
.st4{fill:#1DA1F3;}
.st5{fill:#FEFE00;}
.st6{fill:#25D366;stroke:#FFFFFF;stroke-width:5;stroke-miterlimit:10;}
.st7{fill:#CB2027;}
.st8{fill:#0077B5;}
.st9{fill:url(#SVGID_2_);}
.st10{fill:url(#SVGID_3_);}
.st11{fill:#FF004F;}
.st12{fill:#00F7EF;}
.st13{fill:#5181B8;}
.st14{fill:#395976;}
.st15{fill:#F58220;}
.st16{fill:#E6162D;}
.st17{fill:#FF9933;}
