body {
  background-color: #f0f0f04a;
  font-family: Arial, sans-serif;
  color: #333;
}

h1,
h2,
h3 {
  color: #333;
}

.btn {
  background-color: #ffa500;
  color: #fff;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
}

.header {
  background-color: #333;
  color: #fff;
  padding: 20px;
}

.header h1 {
  margin: 0;
}

.content {
  padding: 20px;
}

.BasicAds {
  min-width: 300px;
  width: 98%;
  min-height: 55px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  line-height: 1.1;
  word-wrap: break-word;
  font-size: small;
  display: block;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;  
  font-weight: bold;


}
.FullAds {
  min-width: 300px;
  width: 98%;
  max-height: 155px;
  cursor: pointer;
  background-image: url('/public/images/fondo_full.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  line-height: 1.1;
  word-wrap: break-word;
  font-size: medium;
  display: block;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  font-weight: bold;

}
.FullBanner {
  min-width: 300px;
  width: 98%;
  max-height: 155px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  line-height: 1.1;
  word-wrap: break-word;
  font-size: medium;
  display: block;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  font-weight: bold;

}

.PremiumAds {
  min-width: 300px;
  width: 98%;
  max-height: 205px;
  cursor: "pointer";
  background-image: url('/public/images/fondo_premium.jpg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
  line-height: 1.1;
  word-wrap: break-word;
  font-size: large;
  display: block;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  font-weight: bold;
}
.PremiumBanner {
  min-width: 300px;
  width: 98%;
  max-height: 205px;
  cursor: "pointer";
  background-size: cover;
  background-repeat: no-repeat;
  line-height: 1.1;
  word-wrap: break-word;
  font-size: large;
  display: block;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;
  font-weight: bold;
}

.GroupAds {
  min-width: 300px;
  width: 98%;
  height: 40px;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  line-height: 1;
  word-wrap: break-word;
  font-size: larger;
  text-align: center;
  display: block;
  justify-content: center;
  align-items: center;
  border: 2px solid gray;
  font-weight: bold;

}

.FavoriteAds {
  width: 100%;
  cursor: pointer;
  background-size: cover;
  background-repeat: no-repeat;
  line-height: 1.1;
  word-wrap: break-word;
  font-size: medium;
  display: block;
  justify-content: center;
  align-items: center;
  border: 1px solid gray;

}